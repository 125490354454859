import * as React from "react";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { Page } from "../components/MenuComponent/MenuComponent.types";
import "../styles/impressum.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const DatenschutzPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LayoutComponent page={Page.PRIVACY} seo={{ title: t("privacy.title") }}>
      <div className="padding-wrapper-60">
        <h2 className="main-headline">
          <div
            dangerouslySetInnerHTML={{
              __html: t("privacy.title"),
            }}
          />
          <span />
        </h2>
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.subTitle", { defaultValue: "" }),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.0"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.1"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.2"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.3"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.4"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.5"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.6"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.7"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.8"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.9"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.10"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.11"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.12"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.13"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.14"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.15"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.16"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.17"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.18"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.19"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.20"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.21"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.22"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.23"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.24"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.25"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.26"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.27"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.28"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.29"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.30"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.31"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.32"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.33"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.34"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.35"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.36"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.37"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.38"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.39"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.40"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.41"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.42"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.43"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.44"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.45"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.46"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.47"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.48"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.49"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.50"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("privacy.content.51"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("privacy.update"),
          }}
        />
      </div>
    </LayoutComponent>
  );
};

export default DatenschutzPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
